import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';

import Home from './Home';

import ErrorNotFound from './ErrorNotFound';
import lcevcplayer from './LcevcPlayer';

import About from './About';


class App extends React.Component {

   constructor(props) {
      super(props);
      var self = this;
      this.state = {
         
      };
      this.onInit();
   }

   onInit() {
      
      }

  render() {
  
   return (

      <div>
         <Router>
            <Switch>
               <Route path="/" component={Home} exact />

               <Route path="/lcevcplayer" exact component={lcevcplayer}  />
               
               <Route path="/about" exact component={About}  />

               <Route path="*" component={ErrorNotFound} />
            </Switch>
         </Router>
          
      </div>


      );
}
}

export default App;