import React, { Component } from 'react';
import './App.css';
import back from './images/goback.png';
import lcevcToggle from './images/lcevc_toggle.png';
import './controls.css'

class LcevcPlayer extends React.Component {

   constructor(props) {
		super(props);
		var self = this;
		this.state = {
			showHideResolutionBtn: false,
			lcevcIsReady: false,
			showLoader:true,
		};
		this.onInit();
	}

  	onInit() {

		const bodyTag = document.querySelector("body");
        const shakaScript = document.createElement("script");
		const lcevcScript = document.createElement("script");

		shakaScript.setAttribute(
			"type",
			"text/javascript"
		  );
		  shakaScript.setAttribute(
			"src",
			"../../js/shaka-player.ui.debug.js"
		  );
		  bodyTag.appendChild(shakaScript);

		  lcevcScript.setAttribute(
          "type",
          "text/javascript"
        );        
		lcevcScript.setAttribute(
			"src",
			"../../js/lcevc_dec.min.js"
		  );
		  bodyTag.appendChild(lcevcScript);


  		var clipUrl = this.props.location.state.clipUrl;

		if(clipUrl) {

			setTimeout(() => {
				const video = document.getElementById('video');
				const ui = video['ui'];
				const controls = ui.getControls();
				const player = controls.getPlayer();

				window.player = player;
				  window.ui = ui;

				// Enable the LCEVC enhancement.
				player.configure('lcevc.enabled', true);
				player.configure('lcevc.drawLogo', true);

				// Enable LCEVC decoding on Safari
				player.configure('streaming.useNativeHlsOnSafari', false);
				player.configure('mediaSource.forceTransmux', true);

				// Listen for error events.
				player.addEventListener('error', this.onError);
				controls.addEventListener('error', this.onError);

				// Try to load a manifest.
				// This is an asynchronous process.
				player.load(clipUrl).then(() => {
					// This runs if the asynchronous load is successful.
					console.info('The video has now been loaded!'); // eslint-disable-line
					this.preHandleLcevcChange();
				}).catch(this.onError); // onError is executed if the asynchronous load fails.

				this.setState({
					lcevcIsReady: this.state.lcevcIsReady,
				});

			},3000);

		} else {
			this.props.history.push('/')
			window.location.reload();
		}
  	}

  	onError(error) {
		console.error('Error code', error.code, 'object', error);
		var codec = localStorage.getItem('codec');
		window.$("#playeralertModal").modal('show');
		if (error.message.includes('CONTENT_UNSUPPORTED_BY_BROWSER')) {
			window.$('#playeralertbody').html('This video format is not supported by your browser.');
		} else if(codec == '3' && error.code == '4032') {
			window.$('#playeralertbody').html('Your player supports LCEVC but unfortunately does not support H265.');
		} else if(error.code == '4032') {
			window.$('#playeralertbody').html('Stream is not supported by the Shaka player.');
		} else {
			window.$('#playeralertbody').html('Something went wrong, Please try again.');
		}
	}

	initFailed() {
		// Handle the failure to load
		console.error('Unable to load the UI library!');
	}

  	componentDidMount() {
  		window.scrollTo(0, 0);
		this.connectionInterval = setInterval(() => {
			this.handleConnectionChange();
		}, 1000);

		this.video = document.getElementById('video');
        this.video.addEventListener("ended", this.isEnded);
		this.video.addEventListener("playing", this.isPlaying);

		document.addEventListener('shaka-ui-load-failed', () => this.initFailed());
		
		let cookieValue = document.cookie.split(';').reduce((ac, str) => Object.assign(ac, {[str.split('=')[0].trim()]: str.split('=')[1]}), {});

		if(cookieValue.togglearrow == undefined) {
			this.setState({
	          showToggleArrow: '0',
	        });
		} else {
			this.setState({
	          showToggleArrow: '1',
	        });
		}
  	}

  	componentWillUnmount() {
		this.video.removeEventListener("playing", this.isPlaying);
	    this.video.removeEventListener("ended", this.isEnded);
		clearInterval(this.connectionInterval);
	    window.$("#playeralertModal").modal('hide');
		if(window.player) {
			window.player.unload();
			window.player.destroy();
		}
		clearInterval(this.seekInterval);
  	}

	/*handleConnectionChange*/
	handleConnectionChange = () => {
		this.video = document.getElementById('video');
		const condition = navigator.onLine ? 'online' : 'offline';
		if(condition == 'offline') {
			this.video.pause();
			window.$("#playeralertModal").modal('show');
			window.$('#playeralertbody').html('There appears to be a problem with your internet connection. Please check connections and retry.');
		}
	}

	preHandleLcevcChange = () => {
        window.LCEVCdec.instance._enableLcevc(false, /*force re-render*/ true);
	}

	/*IsPlaying function*/
	isPlaying = (event) => {
	  	this.setState({
			showLoader: false,
		});
	}
  /*End of IsPlaying function*/

	/*IsEnded function*/
    isEnded = (event) => {
		this.video.currentTime = 0;
        this.video.play();
    }
    /*End of IsEnded function*/



 render() {

	this.iOSCheck = (/iPhone/i.test(navigator.userAgent));

	this.goback = () => (event) => {
		if (document.fullscreenElement) {
		  document.exitFullscreen();
		}
		window.history.back();
	}

	this.handleLcevcChange = (event) => {
		this.setState({
          lcevcIsReady: event.target.checked,
          showToggleArrow: '1',
        });

        document.cookie = "togglearrow=1";

        if(event.target.checked) {
			window.LCEVCdec.instance._enableLcevc(true, /*force re-render*/ true);
        } else {
			window.LCEVCdec.instance._enableLcevc(false, /*force re-render*/ true);
        }
	}


   return (

		<div style={{backgroundColor: '#000'}}>

			<div data-shaka-player-container id="videoContainer" style={{maxWidth: '100%'}}>
				<video data-shaka-player id="video" playsInline autoPlay={!this.iOSCheck} style={{width: '100%', height: '100vh', backgroundColor: '#000000'}}></video>
			
				{/*LCEVC logo, fullscreen and info buttons*/}
				<div id="top_label" className="ptoplabel">
					<span>&nbsp;&nbsp;<img id="goback" onClick={this.goback()} className="pplayergoback" src={back} />&nbsp;&nbsp;</span>
				</div>


				<div> 
					{ 
					(this.state.showToggleArrow == '0')?( 
						<div id="toggle_arrow_dropdown"></div>
					) : ( <span></span> ) 
					}
				</div>

				<div> 
					{ 
					(this.state.showLoader)?( 
						<div id="loading"></div>
					) : ( <span></span> ) 
					}
				</div>


				<div className="lcevc_player_controls_container">
					<div id="controls" className="pseekbarcontrols">
						<div className="pseekbarcontainer" id="pseekbarcontainer">

							<div className="toggleLCEVC_container">
								<div className="toggleLCEVC_img"><img id="toggleLCEVC" src={lcevcToggle} alt="LCEVC" /></div>
								<span>&nbsp;&nbsp;</span>
								<div className="onoffswitch">
								<input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" defaultChecked={this.state.lcevcIsReady}
									onChange={this.handleLcevcChange} />
								<label className="onoffswitch-label" htmlFor="myonoffswitch">
									<span className="onoffswitch-inner"></span>
									<span className="onoffswitch-switch"></span>
								</label>
								</div>			    	
							</div>

						</div>
					</div>
				</div>

				<div className="modal fade" id="playeralertModal" role="dialog">
					<div className="modal-dialog" >
						<div className="modal-content">
							<div className="modal-body" id="playeralertbody">
							</div>
							<div className="playeralert_modal_btn_container">
								<span className="cursor_pointer playeralert_modal_btn" data-dismiss="modal" onClick={this.goback()}>Ok</span>
							</div>
						</div>
					</div>
				</div>

			</div>

	    </div>

      );
   }
}

export default LcevcPlayer; 