import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import logo from './images/logo_white.png';
import welcomeuser from './images/hamburger_icon.png';
import play from './images/1.png';
import replacement from './images/poster_replacement_old.png';
import welcomeLCEVC from './images/lcevc_toggle.png';
import welcomeToggle from './images/toggle_btn.png';

import * as version from './Version';


class Home extends React.Component {
 

  constructor(props) {
      super(props);
      this.state = { 
        jfinalLists: [],
        clipUrl: '', 
    };
    this.onInit();
  }

  onInit() {

    let cookieValue = document.cookie.split(';').reduce((ac, str) => Object.assign(ac, {[str.split('=')[0].trim()]: str.split('=')[1]}), {});

    if(cookieValue.expWelcomePopup == undefined) {
      window.setTimeout( function(){
        window.$('#expWelcomePopupModal').modal('show');
      }, 500 );
    }

    fetch('https://s3-eu-west-1.amazonaws.com/origin-staging-lon-v-nova.com/experience/catalog.json')
      .then(response => response.json())
      .then((jsonData) => {
          this.setState({
            jfinalLists: jsonData.Content
          });
      })
      .catch((error) => {
        console.error(error)
      })

    
  }/*end of onInit*/

 render() {

  //console.log(this.state.jfinalLists);

  const parentdiv = {
    minHeight: '100vh',
  }

  this.goToAbout = () => (event) => {
    event.preventDefault();
      this.props.history.push('/about')
  }

  this.onListError = (event,contentId)  => {
    event.target.style.objectFit = 'contain';
    event.target.src = replacement;
  }

  this.toggleExpWelcomepopup = () => (event) => {
      //console.log(event.target.checked);
      if (event.target.checked) {
        document.cookie = "expWelcomePopup=1";
      } 
  }

  this.goToTogglePlayer = (clip) => (event) => {
    //console.log(clip);
    event.preventDefault();
    const condition = navigator.onLine ? 'online' : 'offline';
    
    if(condition == 'offline') {
      alert('There appears to be a problem with your internet connection. Please check connections and retry.');
    } else {
        var isIOS = (/iPhone/i.test(navigator.userAgent));

        if(isIOS) {
          alert('This web player is not supported by iOS devices. Please view on Android or desktop or download one of the V-Nova experience demo applications from the relevant app store');
        } else {
          this.props.history.push( {pathname: "/lcevcplayer",
                state: { clipUrl: clip.Streamingurl }});
          localStorage.setItem('fromHome', '1');
        }
    }
    
  }


   return (
      
      <div style={parentdiv}>

        <div className="col-md-12 col-sm-12 col-xs-12 zero_padding text_align_center headerforweb">
          
          <img src={logo} alt="LOGO" className="tab_logo" />

          <div className="dropdown">
            <img src={welcomeuser}/>
            <div className="dropdown-content">
              <a><span className="cursor_pointer welcome_msg welcome_msg_text" onClick={this.goToAbout()}>About</span></a>
            </div>
          </div>

        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 clips_container">
          
          {
            (this.state.jfinalLists.length > '0')? 
            
            ( <span>
                {this.state.jfinalLists.map((clips,i) => 

                    <div key = {i} className="col-lg-4 col-md-6 col-sm-6 col-xs-12 clips_content" onClick={this.goToTogglePlayer(clips)}>
                        <img className="clips_img" src={clips.Poster} onError={(e) => {e.persist();this.onListError(e)}} />                     
                        <div className="clips_play_btn"></div>
                    </div>
                  
                )}
              </span>
            ) 

            :(<span></span>)
        }

        </div>


        {/*Welcome Popup Modal Window*/}
            <div className="modal fade" id="expWelcomePopupModal" role="dialog">
              <div className="modal-dialog expWelcomePopup_modal_dialog">
                <div className="modal-content expWelcomePopup_modal_content">
                  
                  <div className="modal-body expWelcomePopup_modal_body">
                    <div><b>Welcome to the V-Nova Experience Demo</b></div><br/>
                    <div>
                      Use the LCEVC toggle control in the player to see how MPEG-5 LCEVC enhancement increases both resolution and detail.
                    </div><br/>
                    <div className="text_align_center popup_welcomeImg">
                      <img src={welcomeLCEVC} alt="LCEVC Toggle" className="popup_welcomeLCEVC" />&nbsp;&nbsp;
                      <img src={welcomeToggle} alt="LCEVC Toggle" className="popup_welcomeToggle" />
                    </div><br/>
                    <div>
                      Viewing this site on larger screens is recommended to fully appreciate the effect that LCEVC makes to video experiences.
                    </div><br/>
                    <div>
                      <input className="checkbox_input position_absolute" type="checkbox" name="terms" id="hidepopup" onClick={this.toggleExpWelcomepopup()} />&emsp;&nbsp;&nbsp;&nbsp;<span>Do Not Show Again</span>
                    </div>
                  </div>

                  <div className="expWelcomePopup_btn">
                    <span className="cursor_pointer text_white_color" data-dismiss="modal">Ok</span>&emsp;&emsp;&nbsp;
                  </div>
                 
                </div>
                
              </div>
            </div>
          {/*End of Welcome Popup Modal Window*/}


      <footer className='col-xs-12'>
          <div className='footer_version'>Version : {version.Version}</div>
       </footer>

      </div>


      );
   }

}

export default Home;